import "../styles/mobile.scss";
import logoClimber from "../images/logoClimber.png";
import logoAct from "../images/logoAct.png";
import logoAeg from "../images/logoAeg.png";
import logoGoldsetu from "../images/goldsetu.png";
import logoAiesec from "../images/logoAiesec.svg";
import logoBrew from "../images/logoBrew.png";
import logoEducatly from "../images/logoEducatly.png";
import logoRagya from "../images/logoRagya.png";
import logoStack from "../images/logoStack.png";
import logoBlok from "../images/logoBlok.png";
import flutterBadge from "../images/flutter-badge.png";
import rnBadge from "../images/rn-badge.png";
import webBadge from "../images/web-badge.png";
import codenameUno from "../images/codename_uno.webp";
import ProductCard from "./ProductCard";

const MobileWork = () => {
  return (
    <div className="mobile-work-container">
      <h2>Projects:</h2>
      <div className="commutatus-work">
      <h4>For Goldsetu:</h4>
      <div className="project-card-container">
      <ProductCard
            logo={<img src={logoGoldsetu} className="logo-common" alt="goldsetu" />}
            name="Goldsetu"
            webLink="https://yourstory.com/companies/gold-setu"
            androidLink="https://play.google.com/store/apps/details?id=com.buymyjewel.live&hl=en_IN&pli=1"
            iosLink="https://apps.apple.com/in/app/goldsetu/id1589589890"
          />
      </div>
      </div>
      <div className="commutatus-work">
        <h4>Via Commutatus:</h4>
        <div className="project-card-container">
          <ProductCard
            logo={<img src={logoAeg} className="logo-common" alt="aeg" />}
            name="Advanced Energy Group"
            webLink="https://goadvancedenergy.com/"
            androidLink="https://play.google.com/store/apps/details?id=com.advancedenergygroup.members"
            iosLink="https://apps.apple.com/us/app/advanced-energy-group/id1525173891"
          />
          <ProductCard
            logo={
              <img src={logoEducatly} className="logo-common" alt="Educatly" />
            }
            name="Educatly"
            webLink="https://www.educatly.com/"
            androidLink="https://play.google.com/store/apps/details?id=com.educatly.ambassador"
            iosLink="https://apps.apple.com/us/app/educatly/id1545324204"
          />
            <ProductCard
            logo={<img src={logoBlok} className="logo-blok" alt="blok" />}
            name="Blok Groceries"
          />
          <ProductCard
            logo={
              <img
                src={logoClimber}
                className="logo-common-md"
                alt="MyCaptain"
              />
            }
            name="MyCaptain"
            type="rn"
            webLink="https://www.mycaptain.in/"
            androidLink="https://play.google.com/store/apps/details?id=com.mycaptain"
            iosLink="https://apps.apple.com/us/app/mycaptain/id1473936356"
          />
          <ProductCard
            logo={<img src={logoRagya} className="logo-common" alt="Ragya" />}
            name="Ragya"
            type="rn"
            webLink="https://www.ragya.com/"
            androidLink="https://play.google.com/store/apps/details?id=com.ragya"
            iosLink="https://apps.apple.com/us/app/ragya/id1481987704"
          />
          <ProductCard
            logo={<img src={logoAct} className="logo-common" alt="ACT" />}
            name="Actathon"
            type="rn"
            webLink="https://www.actcorp.in/"
            androidLink="https://play.google.com/store/apps/details?id=com.act.actathon"
            iosLink="https://apps.apple.com/us/app/actathon/id1492890207"
          />
          <ProductCard
            logo={<img src={logoBrew} className="logo-common" alt="combrew" />}
            name="6-pack Beer"
            type="rn"
            webLink="https://6-pack.beer/"
            androidLink="https://play.google.com/store/apps/details?id=com.combrew"
            iosLink="https://apps.apple.com/us/app/6-pack-beer/id1502360606"
          />
          <ProductCard
            logo={<img src={logoAiesec} className="logo-common" alt="expa" />}
            name="EXPA - AIESEC"
            type="web"
            webLink="https://aiesec.org/"
          />
          <ProductCard
            logo={<img src={rnBadge} className="logo-common" alt="ds" />}
            name="Design System - AIESEC"
            type="web"
          />
        </div>
      </div>
      <div className="commutatus-work">
        <h4>Freelancing:</h4>
        <div className="project-card-container">
          <ProductCard
            logo={
              <img src={logoStack} className="logo-common-lg" alt="stack" />
            }
            name="Stack Finance"
            webLink="https://stackinvest.in/"
            androidLink="https://play.google.com/store/apps/details?id=co.stackfinance.stack"
            iosLink="https://apps.apple.com/us/app/id1571255048"
          />
        </div>
      </div>
      <div className="commutatus-work">
        <h4>Personal:</h4>
        <div className="project-card-container">
        <ProductCard
            logo={<img src={codenameUno} className="logo-common-lg" alt="game" />}
            name="Flutter RPG Game"
            webLink="https://codenameuno.sarthakjha.com/"
          />
           <ProductCard
            logo={
              <img src={flutterBadge} className="logo-common-lg" alt="mvc" />
            }
            name="Flutter - Creative designer (Like Canva)"
          />
          <ProductCard
            logo={<img src={webBadge} className="logo-common-lg" alt="article" />}
            name="Article on Flutter"
            webLink="https://medium.com/@sarthak333jha/flutter-tips-for-building-mvps-in-a-startup-964fdd388cb3"
          />
         
          <ProductCard
            logo={
              <img src={flutterBadge} className="logo-common-lg" alt="bloc" />
            }
            name="Flutter BLoC Boilerplate"
            webLink="https://github.com/sarthakjha889/flutter-boilerplate"
          />
          <ProductCard
            logo={
              <img
                src={flutterBadge}
                className="logo-common-lg"
                alt="launcher"
              />
            }
            name="Flutter App Launcher"
            webLink="https://github.com/sarthakjha889/nerd-launcher"
          />
          <ProductCard
            logo={
              <img src={flutterBadge} className="logo-common-lg" alt="mvc" />
            }
            name="Flutter MVC Boilerplate"
            webLink="https://github.com/sarthakjha889/flutter-mvc-boilerplate"
          />
          <ProductCard
            logo={
              <img src={flutterBadge} className="logo-common-lg" alt="sample" />
            }
            name="Flutter Sample App Template"
            webLink="https://github.com/sarthakjha889/VoiceOut"
          />
          <ProductCard
            logo={<img src={rnBadge} className="logo-common-lg" alt="game" />}
            name="React Native Flappy-bird"
            webLink="https://github.com/sarthakjha889/game-test"
          />
          <ProductCard
            logo={<img src={webBadge} className="logo-common-lg" alt="game" />}
            name="Article on React Native"
            webLink="https://medium.com/commutatus/6-things-about-react-native-youll-only-learn-with-experience-3aab2349b686"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileWork;
