import MobileRow from "./mobileRow";
import me from "../images/me.jpg";

const MobileProfile = () => {
  return (
    <>
      <h2>Profile</h2>
      <img src={me} className="profile-picture" alt="me" />
      <div className="heading-container">
        <h3>About</h3>
      </div>
      <MobileRow
        heading="Skills"
        value={["Flutter", "Golang", "SurrealDB", "React Native", "React", "Redux", "GraphQL"]}
      />
      <MobileRow
        heading="Currently"
        value={["Co-Founder & CTO", "at Orderlist"]}
      />
    </>
  );
};

export default MobileProfile;
