import "./../styles/productCard.scss";
import appStoreLogo from "../images/appStoreLogo.png";
import playStoreLogo from "../images/playStoreLogo.png";
import webBadge from "../images/web-badge.png";


const ProductCard = (props) => {
  let { logo, name, webLink, androidLink, iosLink } = props;

  return (
    <div className="product-card-container">
      <div className="product-card-inner-container">
        <div />
        <div>
          {logo}
          <h3>{name}</h3>
        </div>
        <div className="flex-row">
          {webLink && (
            <a
              href={webLink}
              target="_blank"
              rel="noreferrer"
            >
              <div className="store-link-container">
                <img src={webBadge} height={24} alt="playstore" />
              </div>
            </a>
          )}
          {androidLink && (
            <a
              href={androidLink}
              target="_blank"
              rel="noreferrer"
            >
              <div className="store-link-container">
                <img
                  src={playStoreLogo}
                  height={24}
                  style={{ marginLeft: 4 }}
                  alt="playstore"
                />
              </div>
            </a>
          )}
          {iosLink && (
            <a
              href={iosLink}
              target="_blank"
              rel="noreferrer"
            >
              <div className="store-link-container">
                <img src={appStoreLogo} height={24} alt="appstore" />
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
