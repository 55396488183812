import "../styles/mobile.scss";

const MobileNavButton = (props) => {
  let { children, onClick, selected } = props;
  return (
    <div
      className={`mobile-button-container ${
        selected && "mobile-button-selected"
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default MobileNavButton;
