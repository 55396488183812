import "./styles/App.scss";
import Mobile from "./components/Mobile";
import { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";

let analytics;

function App() {
  useEffect(() => {
    const app = initializeApp({
      apiKey: "AIzaSyBcUWZuuDKWa_evprKjCG1OuVFeMC45eeU",
      authDomain: "portfolio2021-9d49c.firebaseapp.com",
      projectId: "portfolio2021-9d49c",
      storageBucket: "portfolio2021-9d49c.appspot.com",
      messagingSenderId: "612964989280",
      appId: "1:612964989280:web:2a5d2d19ee4c4c58f2c605",
      measurementId: "G-46DYMP18SH"
    });

   analytics = getAnalytics(app);
    
    logEvent(analytics,'session_started')
    console.log("There is no spoon.");
    Crisp.configure("82271a31-e6f1-4cbb-ba66-bf88ea5b9835");
    Crisp.setColorTheme("black");
    Crisp.setPosition("right");
    Crisp.setSafeMode(true);
  }, []);


  return (
    <>
      <div className="App">
        <div className="app-text-container">
          <div className="app-text-name-container">
            <h1 className="heading">Sarthak Jha</h1>
          </div>
          <div className="app-text-details-container">
            <p className="heading-text">
            As a versatile developer with over 5 years of experience, I specialize in crafting pixel-perfect and scalable apps using Flutter, React Native, and React.js. I've also been working with Golang and SurrealDB to deliver full-stack apps. I've developed and released 10+ mission-critical apps for prominent companies, including Goldsetu, MyCaptain by TheClimber, Ragya, AEG, and Educatly.
            </p>
          </div>
        </div>
        <div className="app-mobile-container">
          <Mobile />
        </div>
      </div>
      <div className="instructions-container">
        <p className="instructions-text">
          Please interact with the buttons at the bottom of the mobile
          illustration to know more. Or you can just talk to me via the chat
          integration at the bottom-right corner of the screen.
        </p>
      </div>
    </>
  );
}

export default App;
