import "../styles/mobile.scss";

const MobileRow = (props) => {
  let { heading, value } = props;
  return (
    <div className="mobile-row-container">
      <div className="column">
        <p style={{ textAlign: "start" }} className="column-heading">
          {heading}
        </p>
      </div>
      <div className="column">
        <div className="column-value">
          {value?.map((item, index) => {
            return (
              <p
                key={"mobileRow" + index}
                style={{ textAlign: "start", marginBottom: 4 }}
              >
                {item}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MobileRow;
