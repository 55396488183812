import "../styles/mobile.scss";
import { RiChat3Line, RiBriefcaseLine, RiUser3Line } from "react-icons/ri";
import MobileNavButton from "./MobileNavButton";
import { useState } from "react";
import MobileProfile from "./MobileProfile";
import MobileWork from "./MobileWork";
import MobileContact from "./MobileContact";
    
const Mobile = () => {
  let [selectedTab, setSelectedTab] = useState("profile");

  const _getTabContent = (tab) => {
    switch (tab) {
      case "profile":
        return <MobileProfile />;
      case "work":
        return <MobileWork />;
      default:
        return <MobileContact />;
    }
  };

  const goToWork = () => {
    setSelectedTab("work");
  };

  const goToProfile = () => {
    setSelectedTab("profile");
  };

  const goToContact = () => {
    setSelectedTab("contact");
  };

  return (
    <div className="tab-exterior-container">
      <div className="tab-interior-container">
        <div className="scrollable-content">
          <div style={{ marginTop: 50 }}>{_getTabContent(selectedTab)}</div>
        </div>
        <div>
          <div className="navbar">
            <MobileNavButton
              onClick={goToWork}
              selected={selectedTab === "work"}
            >
              <RiBriefcaseLine
                color={selectedTab === "work" ? "white" : "black"}
              />
            </MobileNavButton>
            <MobileNavButton
              onClick={goToProfile}
              selected={selectedTab === "profile"}
            >
              <RiUser3Line
                color={selectedTab === "profile" ? "white" : "black"}
              />
            </MobileNavButton>
            <MobileNavButton
              onClick={goToContact}
              selected={selectedTab === "contact"}
            >
              <RiChat3Line
                color={selectedTab === "contact" ? "white" : "black"}
              />
            </MobileNavButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
