const MobileContact = (props) => {

  return (
    <>
      <h2>Contact</h2>
      <div className="mobile-contact-container">
        <div className="contact-row">
          <div className="contact-heading-container">
            <h4>Email</h4>
          </div>
          <div className="contact-value-container">
            <a href="mailto:sarthak333jha@gmail.com">
              <p>sarthak333jha@gmail.com</p>
            </a>
          </div>
        </div>

        <div className="contact-row">
          <div className="contact-heading-container">
            <h4>Phone no.</h4>
          </div>
          <div className="contact-value-container">
            <a href="tel:+918896299356">
              <p>+91 8896299356</p>
            </a>
          </div>
        </div>

        <div className="contact-row">
          <div className="contact-heading-container">
            <h4>Github</h4>
          </div>
          <div className="contact-value-container">
            <a
              href="https://github.com/sarthakjha889"
              target="_blank"
              rel="noreferrer"
            >
              <p>Github profile</p>
            </a>
          </div>
        </div>

        <div className="contact-row">
          <div className="contact-heading-container">
            <h4>Linkedin</h4>
          </div>
          <div className="contact-value-container">
            <a
              href="https://www.linkedin.com/in/sarthakjha889/"
              target="_blank"
              rel="noreferrer"
            >
              <p>Linkedin profile</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileContact;
